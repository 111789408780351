<template>
  <div >
    <div class="source-list-tab" v-if="tabList.length">
      <div class="source-list-tab-item" @click="goAllSource">
        <p>全部资源</p>
        <img src="../../../assets/images/new_icon/you_gray_icon.png" width="8" height="13" />
      </div>
      <div class="source-list-tab-item" v-for="(item,index) in tabList" :key="item.id" @click="goTab(item,index)">
        <img src="../../../assets/images/new_icon/wenjianjia.png" width="22" height="19" />
        <p>{{ item.name }}</p>
        <img src="../../../assets/images/new_icon/you_gray_icon.png" width="8" height="13" v-if="index < tabList.length - 1" />
      </div>
    </div>
    <Table border :columns="columns" :loading="loading" :data="dataList"></Table>
    <div class="page">
      <Page :total="total" :page-size-opts="[10,20,50,100]" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
    </div>
    <DeleModal :status="deleModalStatus" txt="确定删除吗" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import DeleModal from '../../../components/deleteModal.vue';
import FileSaver from "file-saver";
export default {
  name: "source.vue",
  data(){
    return{
      columns:[
        {
          title: '文件名称',
          key:'name',
          render: (h, params) => {
            return h('div',{
              style:{
                'display':'flex',
                'justifyContent':'flex-start',
                'alignItems':'center',
                'alignCenter':'center',
                'cursor':'pointer'
              },
              on:{
                click:()=>{
                  this.goSourceInfo(params.row)
                }
              }
            },[
              h('img',{
                attrs:{
                  src:params.row.is_dir == '1' ? require('../../../assets/images/new_icon/wenjianjia.png') : require('../../../assets/images/new_icon/wenjian.png'),
                  width: params.row.is_dir == '1' ? '22' : '16',
                  height:params.row.is_dir == '1' ? '20' : '18'
                },
                style:{
                  'marginRight':'5px',
                }
              }),
              h('span',params.row.name)
            ]);
          }
        },
        {
          title: '大小',
          key:'size',
          render: (h, params) => {
            return h('span', params.row.size ? params.row.size : '-' );
          }
        },
        {
          title: '格式',
          key:'file_extension',
          render: (h, params) => {
            return h('span', params.row.is_dir == '1' ? '文件夹' : params.row.file_extension );
          }
        },
        {
          title: '上传日期',
          key:'id',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000),
                    'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '操作',
          render: (h, params) => {
            return h('div', [
              params.row.is_dir == '1' ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.goSourceInfo(params.row)
                  }
                }
              }, '查看详情') : '',
              params.row.is_dir != '1' && h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.downLoad(params.row.path)
                  }
                }
              }, '下载'),

              params.row.is_dir != '1' && h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.check(params.row.path);
                  }
                }
              }, '预览'),
              params.row.is_dir != '1' && this.userId &&  h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.curListData = params.row;
                    this.deleteSource();
                  }
                }
              }, '删除'),
            ]);
          }
        },
      ],
      dataList:[],
      total:0,
      page:1,
      pageSize:10,
      loading:false,
      deleModalStatus:false,
      modalLoading:false,
      curData:{},
      tabList:[],
      curListData:{},
    }
  },
  components:{
    DeleModal
  },
  props:{
    userId:{
      type:String,
      default:''
    },
    courseId:{
      type:String,
      default:''
    }
  },
  created(){
    this.getList();
  },
  methods:{
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
      };
      if(this.userId){
        params.user_id = this.userId;
      }else{
        params.user_id = '-1';
      }
      if(this.courseId){
        params.course_id = this.courseId;
      }
      if(this.curData.id){
        params.parent_id = this.curData.id;
      }
      this.loading = true;
      this.api.course.teachingSourceList(params).then((res)=>{
        this.total=Number(res.count);
        this.loading = false;
        this.dataList = res.list;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    deleteSource(){
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        source_id:this.curListData.id,
      }
      this.modalLoading = true;
      this.api.course.teachingSourceDel(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    goAllSource(){
      this.curData = {};
      this.tabList = [];
      this.getList();
    },
    goTab(data,index){
      this.curData = data;
      this.tabList = this.tabList.slice(0,index + 1);
      this.getList();
    },
    goSourceInfo(data){
      this.curData = data;
      if(this.curData.is_dir == '1'){
        this.tabList.push({
          id:this.curData.id,
          name:this.curData.name
        })
        this.getList();
      }

    },
    check(data){
      window.open(data,'_blank')
    },
    downLoad(data){
      // util.downloadFile(this,data);
      let name = data.split('/');
      name = name[name.length -1].split('.');
      name = name[0] + '.' + name[1];
      FileSaver.saveAs(data,name);
    },
  }
}
</script>

<style scoped lang="scss">
.source-list-tab{
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .source-list-tab-item{
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    >p{
      margin: 0 10px;
    }
  }
}
.alignC{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
