<template>
<div class="chapters">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="chapters-cont">
    <div class="chapters-detail" v-if="courseId">
      <div class="chapters-detail-tit">
        <p>{{ course.name }}</p>
      </div>
      <div class="chapters-detail-desc">{{course.attr && course.attr.content}}</div>
      <div class="chapters-detail-nums">
        <div class="chapters-detail-nums-val">
          <div class="chapters-detail-num">共{{course.lesson_count}}课时</div>
          <div class="chapters-detail-num">{{course.lesson_week_count}}周课</div>
          <div class="chapters-detail-num" v-if="total_ppt_count > 0">{{total_ppt_count}}个课件</div>
          <div class="chapters-detail-num" v-if="total_video_count > 0">{{total_video_count}}个视频</div>
          <div class="chapters-detail-num" v-if="Number(total_experiment_count) + Number(total_source_link_count) > 0">{{Number(total_experiment_count) + Number(total_source_link_count)}}个实验</div>
          <div class="chapters-detail-num" v-if="total_enchiridion_count > 0">{{total_enchiridion_count}}个实验手册</div>
          <div class="chapters-detail-num" v-if="mapInfo.data_count > 0">{{mapInfo.data_count}}道题</div>
        </div>
        <div>
         <!-- <div class="check-source-btn" type="primary" v-if="courseId" @click="goSource">查看教学资源</div> -->
        </div>
      </div>

    </div>
    <div class="chapters-detail" v-else>
      <div class="chapters-detail-tit">
        {{ categoryObj.name }}
      </div>
<!--      <div class="chapters-detail-desc">数智增长营销师</div>-->
      <div class="chapters-detail-nums">
<!--        <div class="chapters-detail-num">共  20  节课</div>-->
<!--        <div class="chapters-detail-num">20周课</div>-->
        <div class="chapters-detail-nums-val">
          <div class="chapters-detail-num">{{total_enchiridion_count}}个实验</div>
          <div class="chapters-detail-num">{{mapInfo.data_count}}道题</div>
        </div>
      </div>

    </div>
    <div class="tab">
      <p :class="curTab.id == item.id ? 'active' : ''" v-for="(item,index) in tabList" :key="index" @click="changeTab(item)">{{ item.name }}</p>
    </div>
    <div v-if="course.id || mapId">
        <component :is="curTab.compontName" :tree="dataList" :categoryId="categoryId" :courseId="course.id" ></component>
    </div>
<!--    <Directory :tree="dataList" :categoryId="categoryId"></Directory>-->
  </div>
</div>
</template>

<script>
import Directory from './components/common/directory'
// import StuTask from '../course/components/list/stuTask';
import Source from '../course/components/source'
// import StuInteract from '../course/components/list/stuInteract'
// import StuExam from '../course/components/list/stuExam'
import WeeklySchedule from "./components/common/WeeklySchedule.vue";
export default {
  name: "chapters.vue",
  data(){
    return{
      courseId:'',
      mapId:'',
      exerciseId:'',
      trainId:'',
      categoryId:'',
      course:{},
      categoryObj:{},
      mapInfo:{},
      total_enchiridion_count:0,
      dataList:[],
      tabList:[
        {
          name:'章节目录',
          id:'0',
          compontName:'Directory',
          tab:'directory'
        },
        {
          name:'教学资源',
          id:'1',
          compontName:'Source',
          tab:'source'
        },
        // {
        //   name:'课后练习',
        //   id:'2',
        //   compontName:'StuTask',
        //   tab:'task'
        // },
        // {
        //   name:'考试测试',
        //   id:'3',
        //   compontName:'StuExam',
        //   tab:'exam'
        // },
        // {
        //   name:'互动答题',
        //   id:'4',
        //   compontName:'StuInteract',
        //   tab:'interact'
        // },
        {
          name:"周课表",
          id:"5",
          compontName:"WeeklySchedule",
          tab:"WeeklySchedule"
        }
      ],
      curTab:{
        name:'目录',
        id:'0',
        compontName:'Directory',
        tab:'directory'
      },
      tab:'',
      isStudent:null,
      total_ppt_count:0,
      total_video_count:0,
      total_experiment_count:0,
      total_source_link_count:0,
    }
  },
  components:{
    Directory,
    // StuTask,
    Source,
    // StuInteract,
    // StuExam,
    WeeklySchedule
  },
  created(){
    this.mapId = this.$route.query.mapId || '';
    this.courseId = this.$route.query.courseId || '';
    // this.taskId = this.$route.query.taskId || '';
    this.exerciseId = this.$route.query.exerciseId || '';
    this.trainId = this.$route.query.trainId || '';
    this.tab = this.$route.query.tab || '';
    if(!this.courseId){
      this.tabList = [
        {
          name:'目录',
          id:'0',
          compontName:'Directory',
          tab:'directory'
        },
      ];
    }
    if(this.tab){
      this.curTab = this.tabList.filter((item)=>{
        return item.tab == this.tab;
      })[0];
    }
    this.getDetail();
  },
  methods:{
    changeTab(data){
      this.curTab = data || {
        name:'学习任务',
        id:'2',
        compontName:'Task',
        tab:'task'
      };
      this.$router.push({
        path:this.$route.path,
        query:{
          tab:data ? data.tab :'directory',
          courseId:this.courseId
        }
      })
    },
    getDetail(){

      if(this.courseId || this.exerciseId){
        let params = {
          with_source:1
        };
        if(this.exerciseId){
          params.exercise_id = this.exerciseId;
          params.course_id = this.courseId;
        }else if(this.courseId){
          params.course_id = this.courseId;
        }

        this.api.course.mCourseIndex(params).then((res)=>{
          this.dataList = res.map_tree;
          this.dataList.forEach((item)=>{
            let len = item.children.filter((sItem)=>{
              return sItem.have_finished < sItem.data_count
            }).length;
            if(len && !this.isOpen){
              this.$set(item,'show',true);
              this.isOpen = true;
            }else{
              this.$set(item,'show',false);
            }
          });

          this.mapInfo = res.map;
          this.categoryId = res.category.id;
          this.course = res.course;
          this.total_enchiridion_count = res.total_enchiridion_count;
          this.total_ppt_count = res.total_ppt_count;
          this.total_video_count = res.total_video_count;
          this.total_experiment_count = res.total_experiment_count;
          this.total_source_link_count = res.total_source_link_count;
          this.dataBurialPoint('course');

        })
      }else{
        let params = {
          with_source:'1',
        };
        params.action = 'mapIndex';
        params.map_id = this.mapId;
        this.api.dataset.trainExecute(params).then((res)=>{
          this.dataList = res.map_tree;

          this.dataList.forEach((item)=>{
            let len = item.children.filter((sItem)=>{
              return sItem.have_finished < sItem.data_count
            }).length;
            if(len && !this.isOpen){
              this.$set(item,'show',true);
              this.isOpen = true;
            }else{
              this.$set(item,'show',false);
            }
          });
          console.log(this.dataList,'this.dataListthis.dataList')
          this.mapInfo = res.map;
          this.categoryId = res.category.id;
          this.categoryObj = res.category;
          this.total_enchiridion_count = res.total_enchiridion_count;
        })
      }

    },
    dataBurialPoint(type,index){
      const { courseId,mapId,mdNode }=this;
      let sourceId="";
      if(type==="ppt"){
        sourceId=mdNode.ppt_info[0].id;
        if(index>0){
          sourceId=mdNode.ppt_info[index].id;
        }
      }else if(type==="video"){
        sourceId=mdNode.vedio_info[0].id;
        if(index>0){
          sourceId=mdNode.vedio_info[index].id;
        }
      }else if(type==="enchiridion"){
        sourceId=mdNode.enchiridion_info[0].id;
        if(index>0){
          sourceId=mdNode.enchiridion_info[index].id;
        }
      }else if(type==="data"){
        sourceId=mdNode.node_child_id;
      }else if(type==="course"){
        sourceId =this.courseId
      }
      this.api.dataset.dataBurialPoint({
        course_id:courseId,
        source_type:type,
        map_id:mapId,
        node_id:mdNode?.node_child_id,
        source_id:sourceId
      }).then(res=>{
        console.log(res,"数据埋点");
      })
    },
    goSource(){
      this.$router.push({
        path:'/stu/course/source-manage',
        query:{
          id:this.courseId
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.chapters{
  padding: 20px;
  font-size: 14px;
  height: 100%;
  overflow-y: auto;
  .chapters-cont{
    margin-top: 20px;
    padding: 20px;
    background: #FFFFFF;
    .check-source-btn{
      margin-left: 10px;
      padding: 0 20px;
      height: 30px;
      background: #333333;
      border-radius: 15px;
      font-size: 14px;
      color:#FFFFFF;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }

    .chapters-detail{
      padding: 20px;
      //background: #F6F7FA;
      background: url("../../assets/images/new_icon/course_bj.png") no-repeat ;
      background-size: cover;
      border-radius: 6px;
      color:#FFFFFF;

      .chapters-detail-tit{
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: bold;

      }
      .chapters-detail-desc{
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 30px;
      }
      .chapters-detail-nums{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .chapters-detail-num{
          margin-right: 20px;
          font-size: 14px;
        }
        .chapters-detail-nums-val{
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
    .tab{
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #EEEEEE;
      color: #666666;

      >p{
        padding-bottom: 10px;
        margin-right: 30px;
        cursor: pointer;
      }
      .active{
        font-weight: bold;
        color: #4A79FF;
        border-bottom: 4px solid #5782FF;
      }
    }
  }
}
</style>
