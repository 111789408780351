<template>
  <el-drawer :visible.sync="drawerShow" :size="1200" @close="$emit('close')">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">周考试测试</div>
    </div>
    <div style="padding: 20px;">
      <Table border :columns="columns" :loading="loading" :data="data"></Table>
      <div class="page">
        <Page :total="total" :page-size="pageSize" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "stuExam",
  props:{
    show:{
      type:Boolean,
      default:false
    },
    courseId:{
      type:String,
      required:true
    },
    taskId:{
      type:String,
      required:true
    }
  },
  data(){
    return{
      drawerShow:false,
      data:[],
      total:0,
      page:1,
      pageSize:10,
      loading:false,
      columns: [
        {
          title: this.$t('dataset_trainuser_table_index'),
          key:'id',
        },
        {
          title: this.$t('dataset_trainuser_table_title'),
          key: 'name',
          minWidth:60,
          render: (h, params) => {
            // return h('router-link', {
            //   props: {
            //     to: {
            //       path: '/trainingcamp/exam-detail',
            //       query: {
            //         id: params.row.id,
            //         tab: 'result'
            //       }
            //     }
            //   },
            //   style:{
            //     color:'#2db7f5',
            //   }
            // }, params.row.name);

            return util.tableColumnTooltip(h,params.row.name);
          }
        },
        {
          title: this.$t('trainingcamp_myexam_timelong'),
          key: 'timelong',
        },
        {
          title: this.$t('trainingcamp_myexam_data_count'),
          key: 'data_count',
        },
        {
          title: '总分',
          render: (h, para) => {
            return h('span', para.row.total_score);
          }
        },
        {
          title: '及格分',
          key: 'pass_score',
        },
        {
          title: this.$t('trainingcamp_myexam_status'),
          key:'score',
          render: (h, para) => {
            if (this.trainStatuses){
              return h('span', this.trainStatuses[para.row.status]);
            }

          }
        },
        {
          title: this.$t('trainingcamp_myexam_user_count'),
          key: 'join_user_count',
        },
        {
          title: this.$t('trainingcamp_myexam_start_time'),
          key: 'right_count',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.start_time * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('trainingcamp_myexam_end_time'),
          key: 'created_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.end_time * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('dataset_trainuser_table_op'),
          minWidth:80,
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.my_joined_count > 0 || params.row.status == 0 || params.row.status == 3
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    // this.goPreExam(params.row)
                    if(params.row.my_join_status == 1){
                      this.startExam(params.row)

                    }else{
                      this.goPreExam(params.row)
                    }

                  }
                }
              }, params.row.my_joined_count > 0 ? this.$t('trainingcamp_myexam_joined') : this.$t('trainingcamp_myexam_start_exam')),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.my_joined_count == 0
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.checkCourseResult(params.row)
                  }
                }
              }, this.$t('trainingcamp_myexam_record_check_result')),
            ]);
          },
        },
      ],
      trainStatuses:{},
    }
  },
  // created(){
  //   this.courseId = this.$route.query.courseId || '';
  //   this.getList();
  // },
  methods:{
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        course_id:this.courseId,
        task_id:this.taskId
      };


      this.api.course.examineMList(params).then((res)=>{

        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        this.trainStatuses = res.statuses;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    goPreExam(data){
      // this.$router.push({
      //   path:'/trainingcamp/pre-course-exam',
      //   query:{
      //     id:data.id,
      //   }
      // })
      this.$router.push({
        path:'/trainingcamp/pre-exam',
        query:{
          id:data.id,
          from:'courseWeek'
        }
      })
    },
    startExam(data){
      if(data.train.id){
        this.$router.push({
          path:'/exam',
          query:{
            id:data.train.id,
          }
        })
      }else{
        this.$router.push({
          path:'/exam',
          query:{
            examineId:data.id,
          }
        })
      }
    },
    checkCourseResult(data){
      this.$router.push({
        path:'/trainingcamp/exam',
        query:{
          id:data.train.id,
          isResult:true
        }
      })
    },
  },
  watch:{
    show:{
      handler:function(newVal){
        if(newVal){
          this.getList();
        }
        this.drawerShow=newVal;
      },immediate:true
    }
  }
}
</script>

<style scoped lang="scss">
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
