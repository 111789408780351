<template>
  <el-drawer :visible.sync="drawerShow" :size="1200" @close="$emit('close')">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">周互动答题</div>
    </div>
    <div style="padding: 20px;">
      <el-table
        :data="tableData"
        border
        class="table"
    >
      <el-table-column
          prop="id"
          label="互动答题标题">
        <template slot-scope="scope">
          {{ scope.row.interact && scope.row.interact.name }}
        </template>
      </el-table-column>
      <el-table-column
          prop="name"
          label="题目数量"
      >
        <template slot-scope="scope">
          {{scope.row.train && scope.row.train.data_count }}
        </template>
      </el-table-column>
      <el-table-column
          prop="data_count"
          label="结束时间">
        <template slot-scope="scope">
          {{util.timeFormatter(new Date(+scope.row.end_time*1000), 'yyyy-MM-dd hh:mm')}}
        </template>
      </el-table-column>
      <el-table-column
          prop="address"
          label="答题状态">
        <template slot-scope="scope">
          {{interactTaskStatuses[scope.row.status]}}
        </template>
      </el-table-column>
      <el-table-column
          prop="data_count"
          label="提交状态">
        <template slot-scope="scope">
          {{submits[scope.row.is_submit]}}
        </template>
      </el-table-column>
      <el-table-column
          prop="address"
          label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="joinExam(scope.row)" v-if="scope.row.status == 0 && scope.row.is_submit == '0'" >立即参与</el-button>
          <el-button type="primary" size="small" @click="checkExam(scope.row)" v-if="scope.row.is_submit == '1' || scope.row.status == '1'">查看答题记录</el-button>
        </template>
      </el-table-column>
      </el-table>
      <div class="page">
        <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "StuInteract",
  props:{
    show:{
      type:Boolean,
      default:false
    },
    courseId:{
      type:String,
      required:true
    },
    taskId:{
      type:String,
      default: ''
    }
  },
  data(){
    return{
      drawerShow:false,
      util:util,
      tableData:[],
      total:0,
      page:1,
      pageSize:10,
      interactTaskStatuses:{},
      submits:{},
    }
  },
  // created(){
  //   this.courseId = this.$route.query.courseId || '';
  //   this.getList();
  //   this.getCourseForm();
  // },
  methods:{
    getList(){
      let params = {
        course_id:this.courseId,
        page:this.page,
        pageSize:this.pageSize
      }
      if(this.taskId){
        params.task_id = this.taskId;
      }
      this.api.course.courseMInteractTaskList(params).then((res)=>{
        this.tableData = res.list;
        this.total = Number(res.count);
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    getCourseForm(){
      this.api.course.courseForm().then((res)=>{
        this.interactTaskStatuses = res.interact_task_statuses;
        this.submits = res.submits;
      })
    },
    joinExam(data){
      this.$router.push({
        path:'/course/interactExam',
        query:{
          id:data.train_id,
        }
      })
    },
    checkExam(data){
      this.$router.push({
        path:'/course/interactExam',
        query:{
          id:data.train_id,
          isResult:true
        }
      })
    }
  },
  watch:{
    show:{
      handler:function(newVal){
        if(newVal){
          this.$nextTick(()=>{
            this.getList();
            this.getCourseForm();
          })
        }
        this.drawerShow=newVal;
      },immediate:true
    }
  }
}
</script>

<style scoped lang="scss">
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
