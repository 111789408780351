<template>
  <div>
    <!-- <el-table :data="tableData" size="small" border>
      <el-table-column label="ID" prop="id" width="80"></el-table-column>
      <el-table-column label="名称" prop="name" show-overflow-tooltip></el-table-column>
      <el-table-column label="状态" width="100">
        <template v-slot="{ row }">
          {{ doneStatus[row.status] }}
        </template>
      </el-table-column>
      <el-table-column label="创建人" prop="user.realname" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column label="创建时间" width="180" show-overflow-tooltip>
        <template v-slot="{ row }">
          {{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd hh:mm') }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220">
        <template v-slo="{ row }">
          <el-button size="small" type="primary" @click="examShow = true">周考试测试</el-button>
          <el-button size="small" type="primary" @click="interactionShow = true">周互动答题</el-button>
        </template>
      </el-table-column>
    </el-table> -->
    <StuExam :courseId="courseId" :taskId="taskId" :show="examShow" @close="examShow = false" />
    <StuInteract :courseId="courseId" :taskId="taskId" :show="interactionShow" @close="interactionShow = false" />

    <div class="directory">
      <div class="directory-item" v-for="(item, index) in tableData" :key="item.id">
        <div class="directory-item-title" @click="expand(item)">
          <div class="left">
            <div>第{{ index + 1 }}周：</div>
            <div style="margin-left: 5px;">{{ item.name }}</div>
          </div>
          <div class="right">
            <el-button size="small" type="primary" @click.stop="toExam(item)">我参与的周考试测试</el-button>
            <el-button size="small" type="primary" style="margin-right: 10px;"
              @click.stop="toInteraction(item)">我参与的周互动答题</el-button>
            <img src="../../../../assets/images/new_icon/x.png" alt=""
              :style="{ transform: item.show ? 'rotate(180deg)' : 'rotate(0deg)' }">
          </div>
        </div>
        <div class="subsection-list" :style="{ maxHeight: item.show ? item.children_total_count * 70 + 'px' : '0px' }">
          <el-tree
              :ref="item.refName"
              :data="item.children"
              node-key="node_child_id"
              label="name"
              default-expand-all
          >
            <div class="directory-subsection-item" slot-scope="{node,data,index}">
              <div class="sub-left">
                <div class="sub-title">{{ data.index < 9 ? "0" + (data.index + 1) : data.index + 1 }}&nbsp;&nbsp;{{ data.node.name }}</div>
                <div class="count" v-if="!data.children || !data.children.length">题目练习 {{ data.have_finished || 0 }}/{{ data.data_count }}</div>
              </div>
              <div class="sub-right" v-if="!data.children || !data.children.length">
                <div class="sub-right-item" @click="viewData(data, 'ppt')" v-if="data.ppt_info.length > 0"><img class="icon" src="../../../../assets/images/course/ppt.png" alt="">课件<span>({{ data.ppt_info.length }}个)</span></div>
                <div class="sub-right-item" @click="viewData(data, 'video')" v-if="data.vedio_info.length > 0"><img class="icon" src="../../../../assets/images/course/video.png" alt="">视频<span>({{ data.vedio_info.length }}个)</span></div>
                <div class="sub-right-item" @click="viewData(data, 'enchiridion')" v-if="data.enchiridion_info.length > 0">
                  <img class="icon" src="../../../../assets/images/course/exper.png" alt="">实验<span>({{ data.enchiridion_info.length }}个)</span>
                </div>
                <div class="sub-right-item" @click="viewData(data, 'data')" v-if="data.data_count > 0"><img class="icon" src="../../../../assets/images/course/train.png" alt="">试题<span>({{data.have_finished || 0}}/{{ data.data_count }}题)</span></div>
              </div>
            </div>
          </el-tree>

          </div>
        </div>
      </div>
      <el-drawer :visible.sync="drawerShow" size="80%">
        <div class="drawer-title" slot="title">
          <div class="drawer-title-text">{{ drawerTitle }}</div>
        </div>
        <div style="padding: 10px 20px; height: calc(100% - 60px);">
          <div class="name-list">
            <div :class="curFileIndex == index ? 'active' : ''" v-for="(item, index) in fileList" :key="item.id"
              @click="changeFileName(index, item)">{{ item.name }}</div>
          </div>
          <div v-if="fileList.length && fileList[curFileIndex].path" style="height: 100%;">
            <video v-if="fileType == 'video'" ref="video" id="videoPlayer" :src="fileList[curFileIndex].path"
              class="video-js vjs-default-skin" style="background-color: black;" controls width="100%" height="100%"
              controlslist="nodownload"></video>
            <div v-if="fileType == 'enchiridion' && fileList[curFileIndex].link"
              style="font-size: 14px;margin-bottom: 10px">
              实操跳转链接：{{ fileList[curFileIndex].link }} <a :href="fileList[curFileIndex].link" target="_blank"
                style="color:#2d8cf0;text-decoration: underline;">点击跳转</a>
            </div>
            <embed v-if="fileType != 'video' && fileList[curFileIndex].path" id="iframe" ref="myIframe"
              :src="fileList[curFileIndex].path" width="100%" height="100%" type="application/pdf" />
          </div>
        </div>
      </el-drawer>
      <el-drawer :visible.sync="experDrawerShow" size="80%">
        <div class="drawer-title" slot="title">
          <div class="drawer-title-text">{{ drawerTitle }}</div>
        </div>
        <div class="experiment">
          <div class="experiment-pdf">
            <div class="experiment-pdf-top">
              <div class="name-list">
                <div :class="curFileIndex == index ? 'active' : ''" v-for="(item, index) in fileList" :key="item.id"
                  @click="changeFileName(index)">{{ item.name }}</div>
              </div>
              <div>
                <el-button v-for="item in experInfoList" :key="item.id" style="margin-left: 10px;"
                  :loading="experLinkLoading && item.id === loadingId" type="primary" size="small"
                  @click="openExperiment(item, true)">{{ item.name }}</el-button>
                <el-button v-for="item in linkInfoList" :key="item.id" style="margin-left: 10px;" type="primary"
                  size="small" @click="openExperiment(item)">{{ item.name }}</el-button>
              </div>
            </div>
            <div v-if="fileList.length && fileList[curFileIndex].path" style="height: calc(100% - 76px);">
              <embed v-if="fileType != 'video' && fileList[curFileIndex].path" id="iframe" ref="myIframe"
                :src="fileList[curFileIndex].path" width="100%" height="100%" type="application/pdf" />
            </div>
          </div>
        </div>

      </el-drawer>
    </div>
</template>

<script>
// 考试测试
import StuExam from '../../../course/components/list/stuExam';
// 互动答题
import StuInteract from '../../../course/components/list/stuInteract';
import util from "@/utils/tools.js";
export default {
  name: "WeeklySchedule",
  props: {
    tree: {
      type: Array,
      default: () => {
        return [];
      }
    },
    courseId: {
      type: String,
      required: true
    },
    categoryId: {
      type: String,
      default: ""
    }
  },
  components: {
    StuExam,
    StuInteract
  },
  created() {
    this.exerciseId = this.$route.query.exerciseId || "";
    this.getTableList();
  },
  data() {
    return {
      util,
      drawerShow: false,
      fileType: "",
      experDrawerShow: false,
      drawerTitle: "",
      // 文件列表
      fileList: [],
      // 当前预览文件的索引
      curFileIndex: 0,
      experInfoList: [],
      experLinkLoading: false,
      loadingId: "",
      linkInfoList: [],
      mapId: "",
      nodeData: {},
      tableData: [],
      doneStatus: {},
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      examShow: false,
      interactionShow: false,
      taskId: "",
      exerciseId: ""
    }
  },
  methods: {
    // 获取表格数据
    getTableList() {
      const { pagination, courseId } = this;
      this.api.course.mTaskList({
        ...pagination,
        course_id: courseId
      }).then(res => {
        res.list.forEach((item, index) => {
          if (index === 0) {
            item.show = true;
          } else {
            item.show = false;
          }
        });
        pagination.total = Number(res.count);
        this.doneStatus = res.doneStatus || {};
        this.tableData = res.list || [];
        this.tableData.forEach((item,index)=>{
          this.recursion(item.children);
        })
      });
    },
    recursion(data){
      data.forEach((item,index)=>{
        this.$set(item,'index',index);
        if(item.children && item.children.length>0){
          this.recursion(item.children);
        }
      })
    },
    toExam(item) {
      this.taskId = item.id;
      this.examShow = true;
    },
    toInteraction(item){
      this.taskId = item.id;
      this.interactionShow = true;
    },
    // 展开某行周任务
    expand(item) {
      item.show = !item.show;
    },
    // 查看
    viewData(item, type) {
      this.fileList = [];
      this.curFileIndex = 0;
      this.nodeData = item;
      if (type === "ppt") {
        this.handleFileData(item.ppt_info);
        this.drawerTitle = `查看课件-${item.node.name}`;
        this.fileType = "ppt";
        this.drawerShow = true;
        this.dataBurialPoint("ppt");
      } else if (type === "video") {
        this.handleFileData(item.vedio_info);
        this.drawerTitle = `查看视频-${item.node.name}`;
        this.fileType = "video";
        this.drawerShow = true;
        this.dataBurialPoint("video");
      } else if (type === "enchiridion") {
        this.handleFileData(item.enchiridion_info);
        this.drawerTitle = `查看实验-${item.node.name}`;
        this.experInfoList = item.experiment_info || [];
        this.linkInfoList = item.link_info || [];
        this.fileType = "enchiridion";
        this.experDrawerShow = true;
        this.dataBurialPoint("enchiridion");
      } else if (type === "data") {
        this.dataBurialPoint("data");
        this.goTrain(item)
      }
    },
    goTrain(item) {
      if (this.courseId || this.exerciseId) {
        this.$router.push({
          path: '/course/trainingcamp/trainDetail',
          query: {
            dataSort: '1',
            data_type: 10,
            generate_id: item.node_child_id,
            from_id: this.courseId || this.exerciseId,
            from_type: '5',
            type: 0,
          }
        })
      } else {
        this.$router.push({
          path: '/trainingcamp/trainDetail',
          query: {
            id: this.categoryId,
            dataSort: '10',
            data_type: 10,
            generate_id: item.node_child_id
          }
        })
      }
    },
    handleFileData(list) {
      list.forEach((item) => {
        if (!item.path.indexOf('http') == 0) {
          item.path = this.constant.URL + '/uploadfile/show/?file=' + item.path;
        }
        this.fileList.push({
          ...item
        })
      });
    },
    // 埋点
    dataBurialPoint(type, index) {
      const { courseId, mapId, nodeData } = this;
      let sourceId = "";
      if (type === "ppt") {
        sourceId = nodeData.ppt_info[0].id;
        if (index > 0) {
          sourceId = nodeData.ppt_info[index].id;
        }
      } else if (type === "video") {
        sourceId = nodeData.vedio_info[0].id;
      } else if (type === "enchiridion") {
        sourceId = nodeData.enchiridion_info[0].id;
      } else if (type === "data") {
        sourceId = nodeData.node_child_id;
      }
      this.api.dataset.dataBurialPoint({
        course_id: courseId,
        source_type: type,
        map_id: mapId,
        node_id: nodeData.node_child_id,
        source_id: sourceId
      });
    },
    // 切换预览文件
    changeFileName(index) {
      this.curFileIndex = index;
      this.dataBurialPoint("ppt",index);
    },
    // 打开实验链接
    openExperiment(item, authUrl) {
      if (authUrl) {
        this.loadingId = item.id;
        this.experLinkLoading = true;
        this.api.course.getLinkAuthUrl({
          experiment_id: item.id
        }).then(res => {
          this.experLinkLoading = false;
          window.open(res.link, "_blank");
        });
      } else {
        window.open(item.path, "_blank");
      }
    }
  },
  watch: {
    tree: {
      handler(newV) {
        this.mapId = newV[0].map_id;
      },
      immediate: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.directory {
  .directory-item {
    .directory-item-title {
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 60px 0 30px;
      height: 60px;
      background-color: #F6F7FA;
      border-radius: 10px;
      font-size: 16px;
      cursor: pointer;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        img {
          width: 17px;
        }
      }
    }

    .subsection-list {
      transition: .3s;
      margin: 10px 20px;
      max-height: 0px;
      transition: max-height 0.5s;
      overflow: hidden;

      .directory-subsection-item {
        height: 70px;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .sub-left {
          font-size: 16px;
          cursor: pointer;

          .sub-title {
            &:hover {
              color: #4E83FF;
            }
          }

          .count {
            margin-top: 6px;
            color: #666666;
            font-size: 14px;
          }
        }

        .sub-right {
          display: flex;
          align-items: center;

          .sub-right-item {
            margin-left: 10px;
            display: flex;
            align-items: center;
            padding: 3px 16px;
            border-radius: 30px;
            word-break: keep-all;
            font-size: 14px;
            color:#333333;
            font-weight: bold;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
            background-color: #EEEEEE;
            &:hover{
              background-color: #5578F6;
              color:#FFFFFF;
              .icon{
                filter:brightness(100);
              }
              >span{
                color:white;
              }
            }
            .icon{
              width: 16px;
              height:16px;
              display:block;
              margin-right: 3px;
            }
            >span{
              color: #666;
              font-weight: normal;
            }
          }
        }
      }
    }
  }


}
.experiment {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  height: calc(100% - 20px);

  .experiment-pdf {
    margin-right: 20px;
    height: 100%;

    .experiment-pdf-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  >div {
    flex: 1;
    width: 0;

  }

  .experiment-r {
    .experiment-r-iframe {
      width: 100%;
      height: calc(100% - 32px);
    }
  }
}

.name-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  >div {
    margin: 0 10px 10px 0;
    padding: 0 10px;
    border: 1px solid #dcdee2;
    height: 35px;
    line-height: 35px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
  }

  .active {
    border: 1px solid #2d8cf0;
    color: #2d8cf0;
  }
}</style>
